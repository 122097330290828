import {useState} from "react";
import {defaultHeaders, usefetch} from "../../helpers/fetch";

export const useGetTopAnswers = () => {
    const [error, setError] = useState(null);
    const [isGettingTopAnswers, setIsGettingTopAnswers] = useState(null);
    const [response, setResponse] = useState(null);
    const [topAnswers, setTopAnswers] = useState(null);
    const [question, setQuestion] = useState(null);
    const [answerCount, setAnswerCount] = useState(null);

    const getTopAnswers = async (data) => {
        setIsGettingTopAnswers(true);
        const endpoint = 'interview/getTopAnswers';
        const response = await usefetch(endpoint, 'post', defaultHeaders(), data);

        if (!response.ok) {
            setError(response.error);
        }

        if (response.ok) {
            setResponse((prevData) => [...prevData, response]);
        }

        setIsGettingTopAnswers(false);
        setTopAnswers(response?.data?.answers);
        setQuestion(response?.data?.question);
        setAnswerCount(response?.data?.answerCount);
        return response;
    };

    return { getTopAnswers, response, topAnswers,  isGettingTopAnswers, question, answerCount, error };
};