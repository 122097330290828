import {useState} from "react";
import {defaultHeaders, usefetch} from "../../helpers/fetch";

export const useInterviewContest = () => {
    const [error, setError] = useState(null);
    const [isAddingToContest, setIsAddingToContest] = useState(null);
    const [response, setResponse] = useState(null);

    const addToInterviewContest = async (data) => {
        setIsAddingToContest(true);

        const response = await usefetch('interview/contest', 'post', defaultHeaders(), data);

        if (!response.ok) {
            setError(response.error);
        }

        if (response.ok) {
            setResponse((prevData) => [...prevData, response]);
        }

        setIsAddingToContest(false);
        return response;
    };

        return { addToInterviewContest, response,  isAddingToContest, error };
};