import React, { useState } from 'react';

import { Stack, Chip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import { copyToClipboard } from '../../../../helpers/ClipboardHelper';
import {
  CopyLinkIcon,
  FacebookIcon,
  WhatsappIcon,
  XIcon,
  LinkedinIcon,
} from '../../../../assets/constants';

export default function ShareStack() {
  const [copied, setCopied] = useState(false);

  const shareUrl = sessionStorage.getItem('answerPermalink');

  const CopyLink = (content) => {
    copyToClipboard(content);
  };

  setTimeout(() => {
    if (copied) {
      setCopied(false);
    }
  }, 1000);

  return (
    <Stack alignItems='center' pb={2} sx={{ width: '100%' }}>
      <Chip
        sx={{
          fontSize: '16px',
          fontWeight: '500',
          height: '46px',
          padding: '0 .6rem',
          borderRadius: '50px',
          color: '#1045ff',
          boxShadow: 'none !important',
          width: 'max-content',
          background: 'rgba(37, 110, 244, 0.12) !important',
          '& .MuiChip-label': {
            width: 'calc(100% - 20px)',
          },
          '& svg': {
            height: '15px !important',
            width: '15px !important',
          },
        }}
        label={shareUrl}
        onClick={() => {
          setCopied(true);
          CopyLink(shareUrl);
          //   setRespMessage('Link Copied Successfully!');
        }}
        onDelete={() => {
          setCopied(true);
          CopyLink(shareUrl);
          //   setRespMessage('Link Copied Successfully!');
        }}
        deleteIcon={
          copied ? (
            <CheckCircleIcon
              sx={{
                color: '#1045ff !important',
                fontSize: '18px !important',
              }}
            />
          ) : (
            <CopyLinkIcon />
          )
        }
      />
      <Stack
        spacing={2}
        pt={'20px'}
        pb={1}
        px={2}
        direction='row'
        alignItems='center'
        justifyContent='center'
        sx={{
          '& .icon': {
            height: '54px',
            width: '54px',
            border: '1px solid #010103',
            borderRadius: '50px',
            alignItems: 'center',
            justifyContent: 'center',
            '& .react-share__ShareButton': {
              height: '20px',
            },
            '& svg': {
              cursor: 'pointer',
              verticalAlign: 'inherit',
            },
          },
        }}
      >
        <Stack className='icon'>
          <FacebookShareButton url={shareUrl}>
            <FacebookIcon />
          </FacebookShareButton>
        </Stack>

        <Stack className='icon'>
          <TwitterShareButton url={shareUrl}>
            <XIcon />
          </TwitterShareButton>
        </Stack>

        <Stack className='icon'>
          <LinkedinShareButton url={shareUrl}>
            <LinkedinIcon />
          </LinkedinShareButton>
        </Stack>

        <Stack className='icon'>
          <WhatsappShareButton url={shareUrl}>
            <WhatsappIcon />
          </WhatsappShareButton>
        </Stack>
      </Stack>
    </Stack>
  );
}
