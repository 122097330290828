// NavigationHandler.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const NavigationHandler = () => {
  const location = useLocation();

  // Your custom function to handle back/forward navigation
  const handleNavigation = () => {
    console.log('Back or forward button pressed');
    // Add your custom logic here
  };

  useEffect(() => {
    const handlePopState = () => {
      handleNavigation();
    };

    // Add the event listener for popstate
    window.addEventListener('popstate', handlePopState);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []); // Empty dependency array ensures this runs once on mount

  return null; // This component does not render anything
};

export default NavigationHandler;
