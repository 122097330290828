import React, {useEffect, useState} from 'react';

import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Drawer, Stack, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Style from './style';
import {useInterviewContest, useInterviewQuestions} from "../../../../services/career-coach/useInterviewQuestions";

const Styles = makeStyles((theme) => Style(theme));

export default function ChangeQuestion({ from, toggle, setToggle }) {
  const classes = Styles();
  const {getQuestions, questions} =  useInterviewQuestions();
  const navigate = useNavigate();

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <>
      <Drawer
        anchor='bottom'
        open={toggle}
        onClose={() => setToggle(false)}
        className={classes.change__question__drawer}
        sx={{
          '--Drawer-transitionDuration': toggle ? '1s' : '0.1s',
        }}
      >
        <Stack py={3} alignItems='center' px={1.5}>
          <Stack
            pb={2}
            width='100%'
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography variant='title16Bold' sx={{ fontWeight: '500' }}>
              Select a Question
            </Typography>
            <KeyboardArrowUpIcon onClick={() => setToggle(false)} />
          </Stack>
          <Stack
            width='100%'
            sx={{
              height: 'calc(70vh - 30px)',
              overflowY: 'auto',
              '& .question__stack': {
                py: '15px',
                '&:first-child': {
                  pt: 0,
                },
                '&:not(:last-child)': {
                  borderBottom: '1px solid #e4e4e4',
                },
              },
            }}
          >
            {questions?.map((question, i) => {
              return (
                <Stack
                  className='question__stack'
                  sx={{ cursor: 'pointer' }}
                  id={question?.id}
                  width='100%'
                  key={question?.question + 1}
                  onClick={() => {
                      if(from === 'best-interview') {
                          navigate(`/career-coach/best-interviews/${question?.id}`);
                          setToggle(false);
                      } else {
                          if(question.sharing_url) {
                              window.location.href = question.sharing_url;
                          }
                      }
                  }}
                >
                  <Typography
                    pb={'11px'}
                    variant='title14Bold'
                    sx={{ fontWeight: '400' }}
                  >
                    {question?.question}
                  </Typography>
                  <Typography
                    py={0.8}
                    px={1}
                    variant='title14Bold'
                    sx={{
                      width: 'max-content',
                      fontWeight: '700',
                      background: '#F9F3E6',
                      borderRadius: '6px',
                      color: '#E8B029',
                    }}
                  >
                    {question?.num_people_answered} People
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
}
