import React, { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import 'swiper/swiper-bundle.css';
import ReactPlayer from 'react-player';
import { Swiper, SwiperSlide } from 'swiper/react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { LinearProgress, Stack, Typography } from '@mui/material';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import StarRating from './StarRating';
import ChangeQuestion from '../ChangeQuestion';
import Button from '../../../../common/Button';
import { isEmpty } from '../../../../helpers/common';
import { SITE_AT } from '../../../../helpers/utils';

import {
  ShareFillIcon,
  BestInterviewCC,
  DashboardIconCC,
} from '../../../../assets/constants';
import { useAuthContext } from '../../../../Auth/useAuthContext';
import { useLogPayment } from '../../../../services/useLogPayment';
import { createCookie, readCookie } from '../../../../helpers/CookieHelper';
import { useViewVideo } from '../../../../services/career-coach/useViewVideo';
import { useGetInterviewByLink } from '../../../../services/career-coach/useGetInterviewByLink';
import { CareerCoachInterviewContext } from '../../VideoInterview/CareerCoachInterviewContext';
import { APP_ENV } from '../../../../helpers/constants';
import App from '../../../../App';

export default function VotingMain() {
  const mediaRefs = useRef([]);
  const navigate = useNavigate();
  const { answerId } = useParams();

  const [isMuted, setIsMuted] = useState(false);
  const [thumbnial, setThumbnail] = useState(true);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
  const [changeQuestionDrawer, setChangeQuestionDrawer] = useState(false);
  const swiperRef = useRef(null);

  const { user } = useAuthContext();
  const { viewVideo } = useViewVideo();
  const {
    setId,
    setQType,
    setToggle,
    handleShare,
    setCurrentQuestion,
    setStartInterview,
    setCountdown,
    setRerecord,
  } = useContext(CareerCoachInterviewContext);
  const { answers, getNextPageInterviews, totCount } = useGetInterviewByLink();
  const { logPayment } = useLogPayment();

  useEffect(() => {
    console.log(readCookie('userCookie'));
    console.log(isEmpty(user));
    // debugger;
    getNextPageInterviews({
      start: 0,
      answerId: answerId,
      userCookie: isEmpty(user) ? readCookie('userCookie') : undefined,
    }).then((resp) => {
      if (!isEmpty(resp?.answers?.[0]?.answerId)) {
        viewVideo({
          userCookie: isEmpty(user) ? resp?.userCookie : undefined,
          interviewId: !isEmpty(resp?.answers[0]?.answerId)
            ? resp?.answers[0]?.answerId
            : undefined,
        });
      }
      if (
        !isEmpty(resp?.userCookie) &&
        isEmpty(user) &&
        isEmpty(readCookie('userCookie'))
      ) {
        createCookie('userCookie', resp?.userCookie, 30);
      }
    });
  }, []);

  const [videoStates, setVideoStates] = useState([]);
  const [questionVisible, setQuestionVisible] = useState(false);
  const [buttonText, setButtonText] = useState('Practice & Win Rs. 100,000'); // Initial button text
  const [isFirstTime, setIsFirstTime] = useState(true); // Track if it's the first click

  useEffect(() => {
    if (answers.length > 0) {
      // Initialize videoStates for each answer (video) with default values
      const initialVideoStates = answers.map(() => ({
        isPlaying: false,
        videoProgress: 0,
        totalTime: 0,
      }));
      setVideoStates(initialVideoStates);
    }
  }, [answers]); // Trigger this when answers are updated

  useEffect(() => {
    // Hide question stack after 3 seconds on first render
    const timer = setTimeout(() => {
      setQuestionVisible(true);
      console.log('Checking Visibility useEffect');
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const handleButtonClick = (id) => {
    setQuestionVisible(false);
    setButtonText('Start Practice');
    const timer = setTimeout(() => {
      setQuestionVisible(true);
      console.log('Checking Visibility Click');
    }, 4000);

    return () => clearTimeout(timer);
  };

  const updateVideoState = (index, updates) => {
    setVideoStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = { ...newStates[index], ...updates };
      return newStates;
    });
  };

  const togglePlayPause = (index) => {
    const currentVideo = mediaRefs?.current[index];

    if (videoStates[index]?.isPlaying) {
      currentVideo.getInternalPlayer().pause();
    } else {
      currentVideo
        .getInternalPlayer()
        .play()
        .catch((err) => {
          console.log('err', err);
        });
    }
    updateVideoState(index, { isPlaying: !videoStates[index]?.isPlaying });
    setCurrentPlayingIndex(index);
  };

  const handleTimeUpdate = (index, state) => {
    updateVideoState(index, {
      videoProgress: state.played * 100,
    });
  };

  const handleLoadedMetadata = (index) => {
    const currentRef = mediaRefs.current[index];
    if (currentRef) {
      setThumbnail(false);
      updateVideoState(index, { totalTime: currentRef.getDuration() }); // Get total duration
    }
  };

  // Handle Swiper initialization
  const handleSwiperInit = (swiper) => {
    // Set the initial active index
    swiperRef.current = swiper;
    setCurrentPlayingIndex(swiper.activeIndex);
  };
  const handleSlideChange = (swiper) => {
    // Pause all videos when a slide changes
    mediaRefs?.current?.forEach((video, index) => {
      if (videoStates[index]?.isPlaying) {
        // Set the playing state to false to pause the video
        updateVideoState(index, { isPlaying: false });
      }
    });

    const newIndex = swiper.activeIndex;

    // Set the new video to play
    if (mediaRefs.current[newIndex]) {
      mediaRefs?.current[newIndex].seekTo(0);
      updateVideoState(newIndex, { isPlaying: true }); // Start playing the new video
    }

    setCurrentPlayingIndex(newIndex);

    const userCookie = readCookie('userCookie');

    viewVideo({
      interviewId: answers[newIndex]?.answerId,
      userCookie: isEmpty(user) ? userCookie : undefined,
    });

    const nextStart = answers.length;
    if (nextStart < totCount) {
      getNextPageInterviews({
        start: nextStart,
        answerId: answerId,
        firstPage: 'N',
        userCookie: isEmpty(user) ? readCookie('userCookie') : undefined,
      });
    }
  };

  useEffect(() => {
    if (currentPlayingIndex === 0) {
      updateVideoState(currentPlayingIndex, {
        isPlaying: false, // Keep isPlaying as first video will not play itself
      });
    }
  }, [currentPlayingIndex]);
  return (
    <>
      <Stack
        sx={{
          height: '100dvh',
          position: 'relative',
          userSelect: 'none',
          '& .swiper': {
            width: '100%',
            height: '100%',
          },
        }}
      >
        {/* Swiper for swipe-based video switching */}
        <Swiper
          direction='vertical'
          pagination={{ clickable: true }} // Pagination dots for navigating between slides
          mousewheel={{ forceToAxis: true }} // Enable mouse wheel for desktop scroll
          onSlideChange={handleSlideChange}
          onSwiper={handleSwiperInit} // Handle when Swiper is first initialized
        >
          {answers.length > 0 &&
            answers.map((answer, index) => (
              <SwiperSlide key={index}>
                {videoStates[index] && (
                  <Stack
                    sx={{
                      '& video, & .react-player__preview': {
                        background: '#010103',
                        objectFit: 'cover',
                      },
                    }}
                    width='100%'
                    height='100%'
                    onClick={() => togglePlayPause(index)}
                  >
                    <Stack height='100%' width='100%' position='relative'>
                      {thumbnial ? (
                        <img
                          src={answer?.thumbUrl}
                          alt=''
                          style={{
                            position: 'absolute',
                            left: '0',
                            top: '0',
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      ) : null}
                      <ReactPlayer
                        url={answer?.videoUrl}
                        // light={answer?.thumbUrl}
                        style={{ objectFit: 'cover' }}
                        ref={(el) => (mediaRefs.current[index] = el)}
                        width='100%'
                        height='100%'
                        muted={isMuted}
                        controls={false}
                        playing={videoStates[index].isPlaying}
                        preload='auto'
                        playsinline={true}
                        onProgress={(state) => handleTimeUpdate(index, state)}
                        onReady={() => {
                          handleLoadedMetadata(index);
                        }}
                      />
                    </Stack>
                    <Stack
                      sx={{
                        zIndex: '9',
                        color: '#fff',
                        position: 'absolute',
                        left: '0',
                        top: '0',
                        background:
                          'linear-gradient(180deg, rgba(0, 0, 0, 0.16) 15.17%, rgba(0, 0, 0, 0.00) 25.71%, rgba(0, 0, 0, 0.00) 72.74%, rgba(0, 0, 0, 0.40) 88.29%)',
                      }}
                      width='100%'
                      height='100%'
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Stack pt={0} width='100%'>
                        <Stack
                          sx={{
                            '& .MuiLinearProgress-root': {
                              borderRadius: '40px',
                            },
                          }}
                        >
                          <LinearProgress
                            variant='buffer'
                            value={videoStates[index].videoProgress}
                            valueBuffer={videoStates[index].videoProgress + 70}
                            sx={{
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: '#5A5A5C',
                                borderRadius: '40px',
                              },
                              '& .MuiLinearProgress-bar1Buffer': {
                                backgroundColor: '#fff',
                                borderRadius: '40px',
                              },
                            }}
                          />
                        </Stack>
                        <Stack py={'15px'} px={'17px'} alignItems='center'>
                          <StarRating
                            swiperRef={swiperRef}
                            isCurrentUserAnswer={answer?.isCurrentUserAnswer}
                            answerId={answer?.answerId}
                            defaultRating={answer?.vote}
                            avgRating={answer?.avgVote}
                            userCookie={readCookie('userCookie')}
                          />
                        </Stack>
                      </Stack>
                      <Stack
                        onClick={() => togglePlayPause(index)}
                        sx={{
                          display: `${
                            videoStates[index]?.isPlaying ? 'none' : 'block'
                          }`,
                          '& svg': {
                            height: '120px',
                            width: '120px',
                            opacity: '0.3',
                          },
                          zIndex: '9',
                        }}
                      >
                        {videoStates[index]?.isPlaying ? (
                          <PauseCircleIcon />
                        ) : (
                          <PlayArrowRoundedIcon />
                        )}
                      </Stack>

                      <Stack width='100%'>
                        {/* Question and Answer info */}
                        <Stack p={'8px'}>
                          {!questionVisible ? (
                            <Stack
                              width='100%'
                              p={'20px'}
                              sx={{
                                color: '#000',
                                backgroundColor: '#E8B029',
                                borderRadius: '18px',
                              }}
                            >
                              <Stack
                                direction='row'
                                justifyContent='space-between'
                                flexWrap='wrap'
                                pb={'16px'}
                              >
                                <Typography
                                  variant='title18Bold'
                                  sx={{
                                    fontWeight: '700',
                                    display: 'inline-flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  {answer?.name ? answer?.name : 'Anonymous'}
                                  <Typography
                                    variant='title14Bold'
                                    sx={{
                                      display: 'inline-block',
                                      color: '#F8D276',
                                      fontWeight: '500',
                                    }}
                                  >
                                    &nbsp;&nbsp; {answer?.completedAt}
                                  </Typography>
                                </Typography>
                                <Typography
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setChangeQuestionDrawer(true);
                                  }}
                                  variant='title12Bold'
                                  sx={{
                                    fontWeight: '700',
                                    color: '#256EF4',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  Change Question
                                </Typography>
                              </Stack>

                              <Typography
                                pb={2}
                                variant='title14Bold'
                                sx={{
                                  fontWeight: '500',
                                  lineHeight: '18px',
                                }}
                              >
                                {answer?.questionInfo?.question}
                              </Typography>
                              {/* Practice Question Button */}
                              <Button
                                color='primary'
                                title={buttonText}
                                variant='contained'
                                onClick={(e) => {
                                  e?.stopPropagation();
                                  logPayment({
                                    action: 'practiceQuestion',
                                    answerId: answer?.answerId,
                                    userCookie: isEmpty(user)
                                      ? readCookie('userCookie')
                                      : undefined,
                                  });
                                  const careerCoachInterview =
                                    localStorage.getItem(
                                      'careerCoachInterview',
                                    );

                                  if (
                                    !isEmpty(careerCoachInterview) &&
                                    !isEmpty(user)
                                  ) {
                                    setCurrentQuestion(answer?.questionInfo);
                                    setQType(answer?.questionInfo?.type);
                                    setCountdown(
                                      answer?.questionInfo?.countdown,
                                    );
                                    setRerecord(answer?.questionInfo?.reRecord);
                                    setId(careerCoachInterview);
                                    setStartInterview(true);
                                    localStorage.removeItem('answerId');
                                    setToggle(true);
                                    navigate(
                                      `/career-coach/interview/${careerCoachInterview}`,
                                      {
                                        replace: true,
                                      },
                                    );
                                  } else if (isEmpty(user)) {
                                    sessionStorage.setItem(
                                      'viQues',
                                      JSON.stringify(answer?.questionInfo),
                                    );
                                    navigate('/career-coach/mobile');
                                  }
                                }}
                                width='100%'
                                borderRadius='50px'
                                fontSize='14px'
                                height='44px'
                                textTransform='none'
                                fontWeight='500'
                                backgroundColor='#fff !important'
                                textColor='#010103 !important'
                              />
                            </Stack>
                          ) : (
                            <Button
                              color='primary'
                              title='Practice & Win Rs. 100,000'
                              variant='contained'
                              onClick={(e) => {
                                e?.stopPropagation();
                                handleButtonClick(answer?.answerId);
                              }}
                              width='100%'
                              borderRadius='50px'
                              fontSize='14px'
                              height='44px'
                              textTransform='none'
                              fontWeight='500'
                              backgroundColor='#E8B029 !important'
                              textColor='#010103 !important'
                            />
                          )}
                        </Stack>
                        {/* Top bar with back button and share */}
                        <Stack
                          mb={'6px'}
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                          sx={{
                            background: '#fff',
                            color: '#64748B',
                            '& .division__stack': {
                              width: 'calc(100% / 3)',
                              alignItems: 'center',
                              justifyContent: 'center',
                              py: '9px',
                            },
                          }}
                        >
                          <Stack
                            className='division__stack'
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!isEmpty(user)) {
                                navigate('/career-coach/dashboard');
                              } else {
                                navigate('/career-coach');
                              }
                            }}
                          >
                            <Stack>
                              <DashboardIconCC />
                            </Stack>
                            <Typography variant='title12Bold' pt={'7px'}>
                              {!isEmpty(user) ? 'Dashboard' : 'Sign Up'}
                            </Typography>
                          </Stack>
                          <Stack
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(
                                `/career-coach/best-interviews/${answer?.questionInfo?.id}`,
                              );
                            }}
                            className='division__stack'
                            sx={{
                              borderLeft: '1px solid #64748B',
                              borderRight: '1px solid #64748B',
                            }}
                          >
                            <Stack>
                              <BestInterviewCC />
                            </Stack>
                            <Typography variant='title12Bold' pt={'7px'}>
                              Best Interviews
                            </Typography>
                          </Stack>
                          <Stack
                            className='division__stack'
                            onClick={(e) => {
                              e.stopPropagation();
                              handleShare(answer);
                            }}
                          >
                            <Stack
                              sx={{
                                '& svg': {
                                  '& path': {
                                    fill: '#64748B',
                                  },
                                },
                              }}
                            >
                              <ShareFillIcon />
                            </Stack>
                            <Typography variant='title12Bold' pt={'7px'}>
                              Share This
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              </SwiperSlide>
            ))}
        </Swiper>
      </Stack>

      {/* Change Question Drawer */}
      <ChangeQuestion
        toggle={changeQuestionDrawer}
        setToggle={setChangeQuestionDrawer}
      />
    </>
  );
}
