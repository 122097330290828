import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Divider, IconButton, Stack, Typography } from '@mui/material';

import {
  AccountOverviewIconDark,
  CreateCVIcon,
  DashboardIconNew,
  InterviewGuruIcon,
} from '../../../../assets/constants';
import { CartContext } from '../../../../pages/Packages/Cart/CartContext';
import AccountOverviewDrawer from '../../../../pages/Accounts/AccountOverview';

export default function Pages() {
  const navigate = useNavigate();

  const { setAccountDrawerOpen } = useContext(CartContext);

  const PageTab = ({ icon, text, onClick }) => {
    return (
      <Stack
        onClick={onClick}
        sx={{ cursor: 'pointer' }}
        className='page-tab'
        py={2}
        direction='row'
        alignItems='center'
      >
        <IconButton
          sx={{
            borderRadius: '9px',
            height: '43px',
            width: '43px',
            background: '#F4F4F4',
          }}
        >
          {icon}
        </IconButton>
        <Typography
          pl={2}
          variant='title14Bold'
          sx={{ fontWeight: '600', width: 'calc(100% - 50px)' }}
        >
          {text}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      <AccountOverviewDrawer isFromMenubarDrawer />
      <Typography
        pt={'0'}
        pb={'20px'}
        variant='title14Bold'
        sx={{ fontWeight: '600', color: '#ACACAC' }}
      >
        Pages
      </Typography>
      <Stack
        mb={'20px'}
        px={2}
        sx={{
          background: '#fff',
          borderRadius: '11px',
          '& .page-tab': {
            '&:not(:last-child)': {
              borderBottom: '1px solid #e4e4e4',
            },
          },
        }}
      >
        <PageTab
          icon={<DashboardIconNew />}
          text='Dashboard'
          onClick={() => navigate('career-coach/dashboard')}
        />
        <PageTab
          icon={
            <Stack
              sx={{
                '& svg': {
                  '& path': {
                    fill: '#757778',
                  },
                },
              }}
            >
              <AccountOverviewIconDark />
            </Stack>
          }
          text='Credit History'
          onClick={() => {
            setAccountDrawerOpen(true);
          }}
        />
      </Stack>
    </>
  );
}
