import {useState} from "react";
import {defaultHeaders, usefetch} from "../../helpers/fetch";
import {isEmpty} from "../../helpers/common";
import {createCookie, readCookie} from "../../helpers/CookieHelper";

export const useGetInterviewByLink = () => {
    const [error, setError] = useState(null);
    const [isGettingInterview, setIsGettingInterview] = useState(null);
    const [response, setResponse] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [page, setPage] = useState(1);
    const [totCount, setTotalCount] = useState();

    const getByLink = async (data) => {
        setIsGettingInterview(true);

        const resp = await usefetch('interview/getByLink', 'post', defaultHeaders(), data);

        if (!resp.ok) {
            setError(resp.error);
        }

        if (resp.ok) {
            setResponse((prevData) => [...prevData, resp]);
        }

        setIsGettingInterview(false);
        // setAnswers(answers => [...answers, resp.answers]);
        if(resp?.code == '11' ) {
            setAnswers([...answers, ...resp.answers]);
            setTotalCount(Number(resp?.totCount ?? 1));
        }

        return resp;
    };

    const getNextPageInterviews = async (data) => {
        const rowsPerPage = data?.rowsPerPage ??  3;
        setPage(page => page+1);
        return  getByLink({ start: data?.start ?? page,  permalink: data?.answerId, userCookie:data?.userCookie });
    }

    return { getByLink,  response, isGettingInterview, answers,totCount, getNextPageInterviews,error };

};