import { useContext, useEffect, useState } from 'react';
import {
  fileUploadHeaders,
  fileUploadTokenHeaders,
  headers,
  usefetch,
  usefileUploader,
} from '../helpers/fetch';
import { useSnackbar } from '../App';
import {deleteAllCookies, eraseCookie} from '../helpers/CookieHelper';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../pages/Packages/Cart/CartContext';
import Context from "../store/Context";
import {useLogin} from "../services/useLogin";
import {isEmpty, setLocalStorage} from "../helpers/common";

export const useAuth = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [defaultCompany, setDefaultCompany] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [companiesPopUp, setCompaniesPopUp] = useState(false);
  const [globalState, globalDispatch] = useContext(Context);
  const {login} = useLogin();
  const { setSnackbarMessage } = useSnackbar();

  const loginUser = ({email, password}) => {
    return login({email, password}).then((resp) => {
      if (resp?.code === '11') {
        setUser(user => ({...user, ...resp?.data?.userInfo}));
        localStorage.setItem('token', resp?.data?.userInfo?.token);
      }
      return resp;
    });
  };

  const register = () => {
    // TODO: Implement register function
  }

  const logout = () => {
    const careerCoachInterview = localStorage.getItem('careerCoachInterview');
 
    if(isEmpty(careerCoachInterview)) {
      navigate('/login');
    } else {
      navigate('/career-coach');
    }
    localStorage.clear();
    sessionStorage.clear();
    deleteAllCookies();
    globalDispatch({});
    window.location.reload();
  };

  const refreshUserData = async () => {
    let token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    const resp = await usefetch('user/getinfo', 'post', headers(), {});
    if(resp?.code === '00') {
      logout();
      return;
    }
    if (resp?.data?.email) {
      eraseCookie('isGeneric');
    }
    setUser(user => ({...user, ...resp?.data}));
    if (resp?.data?.default_company) {
      setDefaultCompany(resp?.data?.default_company);
    }
    setCompanies(resp?.data?.companies);
    const localpopUpState = localStorage.getItem('setCompaniesPopUp');
    if (!resp?.data?.default_company && resp?.data?.companies?.length > 1 && !localpopUpState) {
      setCompaniesPopUp(true);
    }
    return resp?.data;
  };

  const updateProfileImage = (file) => {
    const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
    if (!file) {
      return;
    }

    if (!allowedTypes.includes(file.type)) {
      setSnackbarMessage('Only PNG, JGP and JPEG are supported files types.');
      return;
    }

    let formData = new FormData();
    formData.append('files', file);
    const newUser = { ...user };
    newUser.image = URL.createObjectURL(file);
    setUser(newUser);

    return usefileUploader(
      'user/uploadImage',
      'post',
      fileUploadTokenHeaders(),
      formData,
    );
  };

  const updateDefaultCompany =  (companyId) => {
    usefetch('CompanyAccess/setDefaultCompany', 'post', headers(), {
      companyId: companyId,
    }).then(function (resp) {
      if (resp?.code === '11') {
        refreshUserData().then(r => {
          setCompaniesPopUp(false);
          localStorage.setItem('setCompaniesPopUp', 'N');
          navigate('/dashboard');
          window.location.reload();
        });
      }
    });
  };

  return {
    user,
    loginUser,
    logout,
    setUser,
    refreshUserData,
    updateProfileImage,
    setCompaniesPopUp,
    companiesPopUp,
    defaultCompany,
    companies,
    updateDefaultCompany,
  };
};
