import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Stack,
  Divider,
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/styles';

import Loader from '../../../common/Loader';
import Permission from './Components/Permissions';
import VideoSection from './Components/VideoSection';
import StepperStack from './Components/StepperStack';
import IOSPermission from './Components/IOSPermissions';
import { isIOS, isSafari } from '../../../helpers/common';
import SelectQuestionsDialog from '../SelectQuestionDialog';
import { CareerCoachInterviewContext } from './CareerCoachInterviewContext';

export default function CareerCoachInterview() {
  const {
    setId,
    loader,
    stream,
    stopAll,
    progress,
    jobTitle,
    isLoading,
    progressBar,
    uploadSpeed,
    simpleLoader,
    clearBlobUrl,
    hasPermission,
    questionDrawer,
    releaseDevices,
    isSavingAnswers,
    setHasPermission,
    isGettingQuestions,
    setQuestionDrawer,
  } = useContext(CareerCoachInterviewContext);
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      setId(id);
    }
  }, [id]);

  //  useDisableBackButton();
  useEffect(() => {
    const handlePopState = async (event) => {
      window.removeEventListener('popstate', handlePopState);
      console.log(stream, 'stream', event);
      stopAll();
      clearBlobUrl();
      await releaseDevices();
      localStorage.setItem('answerId', '');
      navigate('/career-coach/dashboard', { replace: true });
      window.location.reload();
    };
    // Listen for the popstate event, triggered by back button
    window.addEventListener('popstate', handlePopState);

    // Clean up the event listener when the component unmounts
  }, [navigate]);

  useEffect(() => {
    return () => {
      stopAll();
      clearBlobUrl();
      releaseDevices();
    };
  }, [navigate]);

  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {isLoading && (
        <Loader
          textWithLoader
          text1='We are fetching your test. Please hold on!'
          text2='We are still fetching your test. Please hold on!'
          text3='Please do not close this browser window while we are fetching your test!'
        />
      )}
      {loader ? (
        <Loader
          textWithLoader
          text1="Analyzing your interview… just a bit more, we're wrapping it up"
          text2='Extracting voice, transcribing and translating.. almost done!'
          text3='Chill for a sec! This might take a few, depending on your internet speed'
        />
      ) : simpleLoader ? (
        <Loader />
      ) : questionDrawer && false ? (
        <SelectQuestionsDialog
          categoryToggle={questionDrawer}
          setCategoryToggle={setQuestionDrawer}
        />
      ) : isSavingAnswers ? (
        <Loader
          textWithLoader
          text1='We are saving your answer. Please hold on!'
          text2='We are still saving your answer. Please hold on!'
          text3='Please do not close this browser window while we are saving your answer'
          progressBar={progressBar}
          progress={progress}
          uploadSpeed={uploadSpeed}
        />
      ) : (
        <>
          {!hasPermission ? (
            isSafari() || isIOS() ? (
              <IOSPermission
                toggle={!hasPermission}
                setToggle={setHasPermission}
              />
            ) : (
              <Permission
                toggle={!hasPermission}
                setToggle={setHasPermission}
              />
            )
          ) : (
            <>
              <Stack direction='row' height='100dvh'>
                <Stack
                  color='primary.light'
                  p={4.5}
                  sx={{
                    backgroundColor: '#010103',
                    width: '35%',
                    height: '100%',
                    display: { sm: 'none', xs: 'none', md: 'flex' },
                  }}
                >
                  <Typography variant='mainInput'>
                    {isGettingQuestions ? (
                      <Skeleton
                        width='200px'
                        sx={{ backgroundColor: 'rgb(255, 255, 255 , .29)' }}
                      />
                    ) : jobTitle ? (
                      jobTitle
                    ) : (
                      ''
                    )}
                  </Typography>
                  <Divider sx={{ backgroundColor: '#fff', my: 5 }} />
                  <Typography
                    pb={3.75}
                    variant='title20Bold'
                    sx={{ fontWeight: '600' }}
                  >
                    Question
                  </Typography>
                  <StepperStack />
                </Stack>
                <Stack sx={{ width: smMediaquery ? '100%' : '65%' }}>
                  <VideoSection />
                </Stack>
              </Stack>
            </>
          )}
        </>
      )}
    </>
  );
}
