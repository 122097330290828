import {useState} from "react";
import {defaultHeaders, usefetch} from "../../helpers/fetch";

export const useInterviewVote = () => {
    const [error, setError] = useState(null);
    const [isVotingInterview, setIsVotingInterview] = useState(null);
    const [response, setResponse] = useState(null);

    const voteInterview = async (data) => {
        setIsVotingInterview(true);
        const endpoint = 'interview/vote';
        const response = await usefetch(endpoint, 'post', defaultHeaders(), data);

        if (!response.ok) {
            setError(response.error);
        }

        if (response.ok) {
            setResponse((prevData) => [...prevData, response]);
        }

        setIsVotingInterview(false);
        return response;
    };

        return { voteInterview, response,  isVotingInterview, error };
};