import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Loader from '../common/Loader';
import ScrollToTop from './ScrollToTop';
import AdminHome from '../pages/Prompts';
import FormText from '../components/FormText';
import ResetPassword from '../pages/Signin/ResetPassword';
import TestStarted from '../components/TestBuilder/AttemptTest/TestStarted';
import NoPage from '../pages/NoPage';
import CvPublicView from '../pages/Seeker/CvPublicView';
import CvEdit from '../pages/Seeker/CvEdit/CvEditPage';
import { readCookie } from '../helpers/CookieHelper';
import PersistLogin from '../Auth/PresistLogin';
import RequireAuth from '../Auth/RequireAuth';
import { USER_TYPES } from '../helpers/constants';
import { Unauthorized } from '../pages/Unauthorized';
import Popup from '../pages/Accounts/UpgradePopup/Popup';
import UpgradePremium from '../components/Seeker/AccountVerification/UpgradePremium';
import SuccessPopup from '../components/Seeker/AccountVerification/SuccessPopup';
import { OfflineDialog } from '../components/Common/OfflineDialog';
import AccessAccount from '../pages/Accounts/ShareAccount/AccessAccount';
import { SITE_AT } from '../helpers/utils';
import CareerCoachInterview from '../pages/CareerCoach/VideoInterview';
import CareerCoachEvaluation from '../pages/CareerCoach/EvaluationDialog/Main';
import CareerCoachSuccess from '../pages/CareerCoach/Payment/SuccessScreen';
import { Logout } from '../components/Common/Logout';
import { PrivateRoute } from './PrivateRoutes';
import { GuestRoute } from './GuestRoutes';
import Requireuth from './Protected';
import CareerCoachDashboard from '../pages/CareerCoach/Dashboard';
import CareerCoachVotingTerms from '../pages/CareerCoach/Voting/ShareAndSubmission/Terms';
import CareerCoachVotingSubmission from '../pages/CareerCoach/Voting/ShareAndSubmission/Submission';
import VotingMain from '../pages/CareerCoach/Voting/Main';
import BestInterviews from '../pages/CareerCoach/Voting/BestInterviews';

const Main = lazy(() => import('../pages/Main'));
const Terms = lazy(() => import('../pages/TermsPrivacy/Terms'));
const Privacy = lazy(() => import('../pages/TermsPrivacy/Privacy'));
const Signin = lazy(() => import('../pages/Signin'));
const CareerCoachSignin = lazy(() => import('../pages/CareerCoach/Signin'));
const CareerCoachLogout = lazy(() => import('../components/Common/Logout'));
const CareerCoachSigninFlow = lazy(() =>
  import('../pages/CareerCoach/Signin/SignInFlow'),
);
const JobEdit = lazy(() => import('../pages/JobEdit'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Applicant = lazy(() => import('../pages/Applicant'));
const JobDetails = lazy(() => import('../pages/JobDetails'));
const ContactUs = lazy(() => import('../pages/ContactUs'));
const ChooseSkills = lazy(() => import('../pages/ChooseSkills'));
const SeekerRegister = lazy(() => import('../pages/Seeker/Register'));
const SharedJobDetail = lazy(() => import('../pages/SharedJobDetail'));
const CreateResume = lazy(() => import('../pages/Seeker/CreateResume'));
const SeekerDashboard = lazy(() => import('../pages/Seeker/Dashboard'));
// const CareerDashboard = lazy(() => import("../pages/CareerCoach/Dashboard"));
const PDFPreview = lazy(() => import('../pages/PDFs/Invoice'));
const VideoInterview = lazy(() => import('../pages/Seeker/VideoInterview'));
const VideoInterviewNew = lazy(() =>
  import('../pages/Seeker/VideoInterviewNew'),
);

const CodingInterview = lazy(() =>
  import('../pages/Seeker/CodingInterview/Main'),
);
const CVEditDetails = lazy(() => import('../components/Seeker/CVEditDetails'));
const AttemptTest = lazy(() => import('../components/TestBuilder/AttemptTest'));
const Packages = lazy(() => import('../pages/Packages'));
const PackagesNew = lazy(() => import('../pages/PackagesNew'));
const CVEditDetailsNew = lazy(() =>
  import('../components/Seeker/CVEditDetails/index-backup'),
);
const SeekerEditDetails = lazy(() =>
  import('../pages/SharedJobDetail/SeekerEditDetails'),
);

const userToken = localStorage.getItem('token');

const MainRouts = () => (
  <Suspense fallback={<Loader />}>
    <ScrollToTop />
    <OfflineDialog />
    <Popup />
    <UpgradePremium />
    <SuccessPopup />
    <Routes>
      <Route
        path='/'
        element={<Navigate to={userToken ? '/dashboard' : '/signup'} />}
      />
      <Route path='/register' element={<SeekerRegister />} />

      {/* Public routes here */}
      {/*<Route*/}
      {/*    path='/login'*/}
      {/*    element={<FormText fieldName='company_name' />}*/}
      {/*    exact*/}
      {/*/>*/}
      <Route path='/logout' element={<Logout />} />

      {/* We want to protect these routes  */}
      <Route element={<PersistLogin />}>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                USER_TYPES.EMPLOYER,
                USER_TYPES.SEEKER,
                USER_TYPES.RECRUITER,
              ]}
            />
          }
        >
          {/* EMPLOYER AND SEEKER ROUTES */}
          <Route path='/dashboard' element={<Dashboard />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[USER_TYPES.EMPLOYER]} />}>
          {/* EMPLOYER ONLY ROUTES  */}
          {/*<Route*/}
          {/*    path='/dashboard'*/}
          {/*    element={*/}
          {/*      <Dashboard/>*/}
          {/*    }*/}
          {/*/>*/}
        </Route>

        <Route element={<RequireAuth allowedRoles={[USER_TYPES.SEEKER]} />}>
          {/* SEEKER ONLY ROUTES */}
          {/*<Route*/}
          {/*    path='/dashboard/my-cvs'*/}
          {/*    element={<SeekerDashboard/>}*/}
          {/*/>*/}
        </Route>

        <Route path={'/unauthorized'} element={<Unauthorized />} />

        <Route path='/signup' element={<Main />}>
          <Route
            path='/signup/:flow'
            element={<FormText fieldName='jobTitle' />}
            exact
          />
          <Route
            path='/signup'
            element={<FormText fieldName='jobTitle' />}
            exact
          />
          <Route
            path='/signup/experience'
            element={<FormText fieldName='experience' />}
            exact
          />
          <Route
            path='/signup/company_name'
            element={<FormText fieldName='company_name' />}
            exact
          />
          <Route
            path='/signup/manage_employees'
            element={<FormText fieldName='manage_employees' />}
            exact
          />
          <Route
            path='/signup/subordinates_count'
            element={<FormText fieldName='subordinates_count' />}
            exact
          />
          <Route
            path='/signup/other_requirements'
            element={<FormText fieldName='other_requirements' />}
            exact
          />
          <Route
            path='/signup/city_id'
            element={<FormText fieldName='city_id' />}
            exact
          />
          <Route
            path='/signup/maximum_budget'
            element={<FormText fieldName='maximum_budget' />}
            exact
          />
          <Route
            path='/signup/email_address'
            element={<FormText fieldName='email_address' />}
            exact
          />
          <Route
            path='/signup/full_name'
            element={<FormText fieldName='full_name' />}
            exact
          />
          <Route
            path='/signup/mobile_number'
            element={<FormText fieldName='mobile_number' />}
            exact
          />
          <Route
            path='/signup/password_one'
            element={<FormText fieldName='password_one' />}
            exact
          />
          <Route
            path='/signup/password_two'
            element={<FormText fieldName='password_two' />}
            exact
          />
          <Route
            path='/signup/otp'
            element={<FormText fieldName='otp' />}
            exact
          />
        </Route>
        <Route
          path='/job/app/:id'
          element={
            <Requireuth>
              {/*    <JobDetails />  */}
              <Applicant />
            </Requireuth>
          }
        >
          <Route
            path='/job/app/:id/generate-test'
            element={<JobDetails />}
            exact
          />
          <Route path='/job/app/:id/send-test' element={<JobDetails />} exact />
          <Route
            path='/job/app/:id/description'
            element={<JobDetails />}
            exact
          />
          <Route path='/job/app/:id/chat' element={<JobDetails />} exact />
          <Route
            path='/job/app/:id/profile-view'
            element={<JobDetails />}
            exact
          />
        </Route>
        <Route path='/admin/prompts' element={<AdminHome />} />
        <Route path='/attempt-test/:id' element={<AttemptTest />} />

        <Route element={<PrivateRoute />}>
          <Route
            path='/career-coach/dashboard'
            element={<CareerCoachDashboard />}
          />
          <Route path='/career-coach/evaluation'>
            <Route path='' element={<CareerCoachEvaluation />} />
            <Route path=':answerId' element={<CareerCoachEvaluation />} />
          </Route>
          <Route
            path='/career-coach/interview/:id'
            element={<CareerCoachInterview />}
          />

          <Route
            path='/career-coach/success'
            element={<CareerCoachSuccess />}
          />
          <Route
            path='/career-coach/voting/submission'
            element={<CareerCoachVotingSubmission />}
          />
          <Route
            path='/career-coach/voting/terms'
            element={<CareerCoachVotingTerms />}
          />
        </Route>
        <Route element={<GuestRoute />}>
          <Route
            path='/career-coach/best-interviews/:qId'
            element={<BestInterviews />}
          />
          <Route path='/w/:answerId' element={<VotingMain />} />
          <Route path='/career-coach' element={<CareerCoachSignin />} />
          <Route
            path='/career-coach/mobile'
            element={<CareerCoachSigninFlow />}
          />
        </Route>

        {/*<Route path="/career-coach/evaluation">*/}
        {/*  <Route path="" element={<CareerCoachEvaluation />} />*/}
        {/*  <Route path=":answerId" element={<CareerCoachEvaluation />} />*/}
        {/*</Route>*/}

        <Route path='/attempt-test/:id/test-view' element={<TestStarted />} />
        <Route path='/resetPassword' element={<ResetPassword />} />
        <Route path='/access-account' element={<AccessAccount />} />
        <Route path='/pdf-preview' element={<PDFPreview />} />
        <Route path='/login' element={<Signin />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/privacy-policy' element={<Privacy />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/video-interview/:id' element={<VideoInterview />} />
        <Route
          path='/video-interview-new/:id'
          element={<VideoInterviewNew />}
        />

        <Route path='/coding-test/:id' element={<CodingInterview />} />
        <Route
          path='/packages'
          element={true ? <PackagesNew /> : <Packages />}
        />

        <Route
          path='/job/edit/:id'
          element={
            <Requireuth>
              <JobEdit />
            </Requireuth>
          }
        />
        <Route path='/ChooseSkills' element={<ChooseSkills />} />

        <Route path='/dashboard/my-cvs' element={<SeekerDashboard />} />
        <Route
          path='/dashboard/my-applications'
          element={<SeekerDashboard />}
        />
        <Route
          path='/dashboard/change-password'
          element={<SeekerDashboard />}
        />
        <Route
          path='/dashboard/interview-listing'
          element={<SeekerDashboard />}
        />
        <Route path='/seeker-job-apply' element={<SeekerEditDetails />} />
        <Route
          path='/cv-edit-details'
          element={
            readCookie('videoInterviewFlow') === 'Y' ? (
              <CVEditDetails />
            ) : SITE_AT === 'beta' ||
              SITE_AT === 'sailfish' ||
              readCookie('flow') === 'new' ? (
              <CVEditDetailsNew />
            ) : (
              <CVEditDetails />
            )
            // <CVEditDetails />
          }
        >
          <Route
            path='/cv-edit-details/customize-cv'
            element={<CVEditDetails />}
          />
        </Route>
        <Route path='/create-resume/template' element={<CreateResume />}>
          <Route
            path='/create-resume/template/:id'
            element={<CreateResume />}
          />
          {/*<Route path='/create-resume/template/2' element={<CreateResume />} />*/}
        </Route>

        <Route path='/cv-edit' element={<CvEdit />} />
        <Route path='/public-cv/:cvlink' element={<CvPublicView />} />
      </Route>
      <Route element={<GuestRoute />}>
        <Route path='*' element={<NoPage />} />
      </Route>
    </Routes>
  </Suspense>
);
export default MainRouts;
