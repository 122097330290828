import React, { useEffect, useRef, useState } from "react";

import { useTheme } from "@mui/styles";
import { Stack, useMediaQuery } from "@mui/material";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";

export default function VideoStack({ status, mediaBlobUrl, videoRef }) {
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down("md"));
  const mediaRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (mediaRef.current && mediaBlobUrl) {
      mediaRef.current.src = mediaBlobUrl;
      // Reload the media
      mediaRef.current.load();

      const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );

      if (isIOS || isSafari) {
          mediaRef.current.muted = true; // Ensure video is muted to allow autoplay
          const videoElement = mediaRef.current;
          videoElement?.play().catch((error) => {
            // Handle playback errors (e.g., autoplay restrictions)
            console.error("Error during playback:", error);
          });
          // Event listener to check when enough data is available to play
          const handleCanPlay = () => {
            if (videoElement.readyState > 0) {
              // Enough data to play the video
              console.log("fired Played", videoElement.readyState);
              videoElement?.play().catch((error) => {
                // Handle playback errors (e.g., autoplay restrictions)
                console.error("Error during playback:", error);
              });
            }
            setTimeout(() => {
              if (videoElement) {
                videoElement.muted = false; // Ensure video is muted to allow autoplay
                videoElement?.pause();
              }
              console.log("fired stopped", videoElement.readyState);
            }, 1000); // Stop after 2 ms
          };

          videoElement.addEventListener("loadedmetadata", handleCanPlay);
      }
    }
  }, [mediaBlobUrl]);

  const togglePlayPause = () => {
    console.log("mediaRef ", mediaRef, "mediaBlobUrl", mediaBlobUrl);
    if (mediaRef.current) {
      if (isPlaying) {
        mediaRef?.current.pause();
      } else {
        mediaRef?.current.play().catch((error) => {
          // Handle playback errors (e.g., autoplay restrictions)
          console.error("Error during playback:", error);
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Stack
      sx={{
        position: "relative",
        height: "100dvh",
        background: "#010103",
        "& video": {
          transform: "scaleX(-1)",
          borderRadius: "0px",
        },
        "& video::-webkit-media-controls-enclosure": {
          transform: "scaleX(-1)",
        },
      }}
    >
      {status === "stopped" && mediaBlobUrl ? (
        <>
          {console.log("videoTag", mediaBlobUrl)}
          <video
            key={mediaBlobUrl}
            style={{
              objectFit: "cover",
            }}
            width="100%"
            height="100%"
            ref={mediaRef}
            playsInline
            preload="auto"
          />
        </>
      ) : (
        <video
          style={{
            objectFit: "cover",
            transform: "scaleX(-1)",
            "& WebkitMediaControlsPanel": {
              transform: "scaleX(1)",
              backgroundColor: "red",
            },
          }}
          width="100%"
          height="100%"
          ref={videoRef}
          autoPlay
          muted
          playsInline
        />
      )}

      {status === "stopped" && mediaBlobUrl ? (
        <Stack
          sx={{
            height: "61px",
            width: "61px",
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: "9",
            cursor: "pointer",
            transform: "translate(-50%, -50%)",
            "& svg": {
              color: "#fff",
              height: "61px",
              width: "61px",
            },
          }}
          onClick={togglePlayPause}
        >
          {isPlaying ? <PauseCircleIcon /> : <PlayCircleRoundedIcon />}
        </Stack>
      ) : null}
    </Stack>
  );
}
