import {useState} from "react";
import {defaultHeaders, usefetch} from "../../helpers/fetch";

export const useViewVideo = () => {
    const [error, setError] = useState(null);
    const [isViewingVideo, setIsViewingVideo] = useState(null);
    const [response, setResponse] = useState(null);

    const viewVideo = async (data) => {
        setIsViewingVideo(true);
        const endpoint = 'interview/videoView';
        const response = await usefetch(endpoint, 'post', defaultHeaders(), data);

        if (!response.ok) {
            setError(response.error);
        }

        if (response.ok) {
            setResponse((prevData) => [...prevData, response]);
        }

        setIsViewingVideo(false);
        return response;
    };

    return { viewVideo, response,  isViewingVideo, error };
};