import {useState} from "react";
import {defaultHeaders, usefetch} from "../../helpers/fetch";

export const useInterviewQuestions = () => {
    const [error, setError] = useState(null);
    const [isGettingQuestions, setIsGettingQuestions] = useState(null);
    const [response, setResponse] = useState(null);
    const [questions, setQuestions] = useState([]);

    const getQuestions = async (data) => {
        setIsGettingQuestions(true);

        const response = await usefetch('interview/getQuestions', 'post', defaultHeaders(), data);

        if (!response.ok) {
            setError(response.error);
        }

        if (response.ok) {
            setResponse((prevData) => [...prevData, response]);
        }

        setIsGettingQuestions(false);
        setQuestions(response.questions);
        return response;
    };

        return {  getQuestions, response,  isGettingQuestions, questions,  error };
};