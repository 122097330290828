import React, { useContext, useEffect, useState } from 'react';

import {
  Step,
  Stack,
  Stepper,
  StepLabel,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import clsx from 'clsx';
import { useTheme } from '@emotion/react';
import { CareerCoachInterviewContext } from '../CareerCoachInterviewContext';

import {
  AudioPause,
  AudioPlaying,
  ShowHideQuestionIcon,
} from '../../../../assets/constants';

export default function StepperStack() {
  const {
    status,
    startRecord,
    activeStep,
    classes,
    currentQuestion,
    language,
    hideQuestion,
    setHideQuestion,
    mediaBlobUrl,
  } = useContext(CareerCoachInterviewContext);
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(
    new Audio(currentQuestion?.question_audio_mp3),
  );

  useEffect(() => {
    if (!startRecord && status === 'stopped' && mediaBlobUrl) {
      setHideQuestion(true);
    }
  }, [status]);

  // let audio = null;

  // useEffect(() => {
  //   if(currentQuestion?.question_audio_mp3) {
  //     	audio = new Audio(currentQuestion?.question_audio_mp3);
  //   }
  // }, [currentQuestion]);

  return (
    <>
      {startRecord ? null : (
        <IconButton
          onClick={() => setHideQuestion(!hideQuestion)}
          sx={{
            position: 'fixed',
            right: '1rem',
            top: '5rem',
            width: 'max-content',
            boxShadow: '0px 4px 24px 0px #00000026',
            backdropFilter: 'blur(224px)',
            padding: '0 !important',
          }}
        >
          <ShowHideQuestionIcon />
        </IconButton>
      )}
      <Stack
        sx={{
          visibility: startRecord
            ? 'visible'
            : hideQuestion && smMediaquery
            ? 'hidden'
            : 'visible',
          height: startRecord
            ? 'max-content'
            : hideQuestion && smMediaquery
            ? '0px !important'
            : 'max-content',
          width: 'max-content',
          maxWidth: '100%',
        }}
      >
        <Stack
          sx={{
            height: smMediaquery ? 'max-content' : 'calc(100vh - 300px)',
            overflowY: 'auto',
            minWidth: '100%',
            '&::-webkit-scrollbar-button': {
              height: '80px',
            },
            '&::-webkit-scrollbar': {
              width: '5px',
              height: '0px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#e4e4e400',
              boxShadow: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#e4e4e4',
              borderRadius: '16px',
            },
          }}
        >
          <Stepper
            activeStep={Number(activeStep)}
            orientation='vertical'
            className={classes.stepper__stack}
          >
            <Step
              key={currentQuestion?.id}
              className={clsx(
                (activeStep === currentQuestion?.id || smMediaquery) &&
                  classes.acive__step__bg__mob,
              )}
            >
              <StepLabel
                sx={{
                  '& svg': {
                    color: '#fff !important',
                  },
                  '& .MuiStepIcon-text': {
                    fill: '#010103',
                  },
                }}
              >
                <Typography
                  color='primary.light'
                  variant={smMediaquery ? 'title18Bold' : 'title20Bold'}
                  sx={{ fontWeight: '400' }}
                >
                  {language === 'roman'
                    ? currentQuestion?.question_roman
                    : currentQuestion?.question}
                  {/* { activeStep !== currentQuestion?.id
                    ? currentQuestion?.question?.length > 30
                      ? `${currentQuestion?.question?.slice(0, 75)}...`
                      : currentQuestion?.question
                    : currentQuestion?.question}{" "} */}
                </Typography>
              </StepLabel>
            </Step>
          </Stepper>
          {false && (
            <Stack width='100%' alignItems='center' justifyContent='center'>
              <IconButton
                sx={{ mt: 2, p: 0, width: 'max-content' }}
                onClick={() => {
                  if (isPlaying) {
                    audio?.pause();
                    setIsPlaying(false);
                  } else {
                    audio?.play();
                    setIsPlaying(true);
                  }
                }}
              >
                {isPlaying ? <AudioPlaying /> : <AudioPause />}
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
}
