import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

import filledStar from '../../../../assets/images/filled-star-rating.png';
import emptyStar from '../../../../assets/images/empty-star-rating.png';
import { useInterviewVote } from '../../../../services/career-coach/useInerviewVote';
import { isEmpty } from '../../../../helpers/common';
import { useAuthContext } from '../../../../Auth/useAuthContext';

const StarRating = ({
  maxStars = 5,
  onRating,
  answerId,
  defaultRating,
  avgRating,
  isCurrentUserAnswer,
  userCookie,
  swiperRef,
}) => {
  const [rating, setRating] = useState(0); // Final selected rating
  const [hoverRating, setHoverRating] = useState(0); // Hover state for dynamic rating preview
  const { voteInterview, isVotingInterview } = useInterviewVote();
  const { user } = useAuthContext();

  // Handle hover movement to show partial stars
  const handleMouseMove = (e, index) => {
    if (isCurrentUserAnswer === 'Y') {
      return;
    }
    const { left, width } = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - left;
    const percentage = offsetX / width; // Calculate the percentage of the star hovered
    const hoverValue = index + (percentage >= 0.5 ? 1 : 1); // Partial star rating
    setHoverRating(hoverValue); // Set hover rating for display
  };

  // Reset hover state when mouse leaves
  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  useEffect(() => {
    if (isCurrentUserAnswer === 'Y') {
      setRating(Math.round(Number(avgRating)));
    } else {
      setRating(Number(defaultRating ?? 0));
    }
  }, [answerId, avgRating, defaultRating]);

  // Handle click event to set final rating
  const handleClick = (index) => {
    if (isCurrentUserAnswer === 'Y') {
      return;
    }
    setRating(index);

    if (isEmpty(index) || isEmpty(answerId)) {
      return;
    }
    voteInterview({
      vote: index,
      answerId: answerId,
      userCookie: isEmpty(user) ? userCookie : undefined,
    });

    if (onRating) onRating(index); // Trigger callback with the selected rating

    if (swiperRef && swiperRef.current) {
      swiperRef.current.slideNext(); // Using swiperRef inside the component
    }
  };

  // Render star image based on hover or selected rating
  const renderStar = (index) => {
    const currentRating = hoverRating || rating; // Use hover rating if hovering, else use selected rating
    // const isHalf = currentRating > index && currentRating <= index + 0.5; // Check for partial star
    const isFull = currentRating > index + 0.5; // Check for fully filled star

    let starSrc = emptyStar;
    let textColor = '#E8B029'; // Default text color for empty star

    if (isFull) {
      starSrc = filledStar;
      textColor = '#010103'; // Text color for fully filled star
    }
    // else if (isHalf) {
    //   starSrc = halfFilledStar;
    //   textColor = '#010103';
    // }

    return (
      <div style={{ position: 'relative' }} key={index}>
        <img
          src={starSrc}
          alt={
            isFull
              ? 'Filled Star'
              : // : isHalf ? 'Half Filled Star'
                'Empty Star'
          }
          height='32px'
          width='34px'
        />
        <span
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -30%)',
            color: textColor,
            fontSize: '9px',
            fontWeight: '700',
          }}
        >
          {index + 1}
        </span>
      </div>
    );
  };

  return (
    <Stack
      sx={{
        width: '100%',
        background: isCurrentUserAnswer === 'Y' ? '#45340A' : '#45340A',
        opacity: isCurrentUserAnswer === 'Y' ? '0.3' : '1',
        borderRadius: '70px',
        padding: '13px 24px',
      }}
      spacing={1}
      direction='row'
      justifyContent='space-between'
    >
      {Array.from({ length: maxStars }, (_, index) => (
        <div
          key={index}
          onMouseMove={(e) => handleMouseMove(e, index)}
          onMouseLeave={handleMouseLeave}
          onClick={(e) => {
            e.stopPropagation();
            handleClick(index + 1);
          }}
          style={{
            cursor: 'pointer',
            position: 'relative',
          }}
        >
          {renderStar(index)}
        </div>
      ))}
    </Stack>
  );
};

// Prop types for the StarRating component
StarRating.propTypes = {
  maxStars: PropTypes.number, // Number of stars (default is 10)
  onRating: PropTypes.func, // Optional callback when a rating is selected
};

export default StarRating;
