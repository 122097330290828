import React, { useState, useEffect, useContext } from 'react';

import clsx from 'clsx';
import { useTheme, makeStyles } from '@mui/styles';
import CircleIcon from '@mui/icons-material/Circle';
import {
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';

import '../style.css';
import Style from '../style';
import {
  StartRecording,
  StopRecording,
  ReRecording,
  RecordingIconNew,
} from '../../../../assets/constants';
import { CareerCoachInterviewContext } from '../CareerCoachInterviewContext';

const Styles = makeStyles((theme) => Style(theme));

export default function VideoAction() {
  const {
    status,
    setQuestionTime,
    startRecording,
    handleStopRecording,
    setShowAgainVideo,
    clearBlobUrl,
    currentQuestion,
    mediaBlobUrl,
    reRecord,
    startRecord,
    activeStep,
    videoInterviewQuestions,
    isSavingAnswer,
    handleNext,
    setToggle,
    setIsLoading,
    classes,
    setHideQuestion,
    streamCamVideo,
    setSimpleLoader,
  } = useContext(CareerCoachInterviewContext);
  const theme = useTheme();

  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  let [reRecordTries, setRerecordTries] = useState(reRecord);

  const AnimatedButton = ({ handleClick }) => {
    return (
      <Stack width='100%' pr={smMediaquery ? 0 : 3}>
        <Stack
          position='relative'
          width='100%'
          alignItems={smMediaquery ? 'center' : 'flex-end'}
          justifyContent='flex-end'
          onClick={() => {
            setSimpleLoader(true);
            handleClick();
            setTimeout(() => {
              setSimpleLoader(false);
            }, 400);
          }}
          sx={{ zIndex: '9' }}
        >
          <div className='item'>
            <CircleIcon
              sx={{
                width: '40px',
                height: '40px',
                color: ' rgba(237, 69, 69, 1)',
              }}
            />
          </div>
          <div className='circle' style={{ animationDelay: '0s' }}></div>
          <div className='circle' style={{ animationDelay: '1s' }}></div>
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack width='100%'>
      {startRecord && (
        <Stack width='100%' alignItems='center' justifyContent='center'>
          <Typography
            variant='title14Bold'
            sx={{
              fontWeight: '400',
              backgroundColor: '#1d1d3900',
              border: '1px solid #fff',
              color: '#fff',
              borderRadius: '11px',
              height: '56px',
              width: '235px',
              textTransform: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Waiting to start
          </Typography>
        </Stack>
      )}
      {console.log("status ",status)}
      {status === 'recording' && (
        <Stack width='100%' alignItems='center'>
          <IconButton
            onClick={() => {
              setSimpleLoader(true);
              handleStopRecording();
              setTimeout(() => {
                setSimpleLoader(false);
              }, 400);
            }}
            sx={{
              width: 'max-content',
            }}
          >
            <RecordingIconNew />
          </IconButton>
        </Stack>
      )}

      {!startRecord && status === 'stopped' && mediaBlobUrl && (
        <Stack
          p={2}
          direction='row'
          spacing={2}
          width='100%'
          alignItems='center'
          justifyContent='center'
          className={classes.submit__stack}
        >
          {reRecordTries > 0 ? (
            <Typography
              onClick={() => {
                setSimpleLoader(true);
                clearBlobUrl();
                // streamCamVideo();
                setHideQuestion(false);
                setRerecordTries(reRecordTries - 1);
                setTimeout(() => {
                  setToggle(true);
                  setQuestionTime(currentQuestion?.time);
                  setSimpleLoader(false);
                }, 1000);
              }}
              className={clsx(classes.rereord__btn, classes.rerecord__btn)}
            >
              Re-record
            </Typography>
          ) : null}
          <Typography
            onClick={() => {
              handleNext();
              setRerecordTries(reRecord);
            }}
            className={clsx(classes.rereord__btn, classes.submit__btn)}
          >
            Submit Response
            {isSavingAnswer && <CircularProgress color='primary' />}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
